<template>
  <div>
    <b-card no-body>
      <b-card-header class="pl-0 pr-0">
        <b-col
          cols="12"
          sm="7"
          lg="3"
        >
          <form-required
            v-model="role.name"
            vid="name"
            label="Tên Nhóm người dùng"
            custom-v-error="Tên Nhóm người dùng là bắt buộc"
            rules="required"
            @update-value="val => role.name = val"
          />
        </b-col>

        <b-col
          cols="12"
          sm="5"
          lg="2"
        >
          <form-required
            v-model="role.code"
            vid="code"
            label="Mã"
            custom-v-error="Mã là bắt buộc"
            rules="required"
            @update-value="val => role.code = val"
          />
        </b-col>

        <b-col
          cols="12"
          lg="7"
        >
          <form-required
            v-model="role.description"
            vid="description"
            label="Mô tả"
            custom-v-error="Mô tả là bắt buộc"
            rules="required"
            @update-value="val => role.description = val"
          />
        </b-col>
      </b-card-header>
      <b-table
        responsive
        :items="permissions"
        :fields="tableColumns"
        class="thead-primary thead-no-wrap"
      >
        <template #cell(name)="data">
          <span
            :class="data.item.is_allow ? 'text-primary' : ''"
            class="font-weight-bolder"
          >{{ data.item.name }}</span>
        </template>
        <template #cell(description)="data">
          <span :class="data.item.is_allow ? 'text-primary' : ''">{{ data.item.description }}</span>
        </template>
        <template #cell(code)="data">
          <span :class="data.item.is_allow ? 'text-primary' : ''">{{ data.item.code }}</span>
        </template>
        <template #cell(status)="data">
          <b-form-checkbox
            v-model="data.item.is_allow"
            class="custom-control-success"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
      </b-table>
    </b-card>

    <!--    fixed btn-->
    <b-row
      class="sticky-footer justify-content-end"
      align="end"
    >
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          @click="$goback"
        >
          <feather-icon
            icon="ChevronLeftIcon"
          />
          Quay lại
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="ml-50"
          variant="primary"
          @click="createOrSave"
        >
          <feather-icon
            :icon="pageMode==='create'?'PlusIcon':'SaveIcon'"
          />
          {{ pageMode === 'create' ? 'Tạo' : 'Lưu' }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BFormCheckbox, BRow, BTable,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import http from '@/global/http'
import store from '@/store'
import roleStoreModule from '@/views/settings/roles/roleStoreModule'
import FormRequired from '@core/components/form-required/FormRequired.vue'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BCardHeader,
    FormRequired,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // validation rules
      required,
    }
  },
  watch: {
    '$route.fullPath': {
      handler(n) {
        if (n) {
          const rId = this.$route.params.id
          this.pageMode = rId ? 'edit' : 'create'
          if (this.pageMode === 'edit') {
            this.fetchRole(rId)
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchPermission()
  },
  methods: {
    test(e) {
      console.log('role.name = val', e)
    },
    createOrSave() {
      const roleData = {
        ...this.role,
        permission: this.permissions,
      }
      if (this.pageMode === 'create') {
        this.$call(this.createRole(roleData), true).then(data => {
          console.log('$action', data)
          this.$router.push({ name: 'settings-roles-edit', params: { id: data.id } })
        })
      } else {
        this.$call(this.updateRole(roleData), true)
      }
    },
  },
  setup() {
    const ROLE_STORE_MODULE_NAME = 'role'
    if (!store.hasModule(ROLE_STORE_MODULE_NAME)) store.registerModule(ROLE_STORE_MODULE_NAME, roleStoreModule)
    onUnmounted(() => {
      if (store.hasModule(ROLE_STORE_MODULE_NAME)) store.unregisterModule(ROLE_STORE_MODULE_NAME)
    })

    const role = ref({
      name: '',
      code: '',
      description: '',
    })

    const pageMode = ref('create')
    const permissions = ref([])

    const tableColumns = [
      {
        label: '',
        key: 'status',
      },
      {
        label: 'Chức năng',
        key: 'name',
      },
      {
        label: 'Mô tả',
        key: 'description',
      },
    ]

    const fetchPermission = () => http.handle(store.dispatch('role/fetchPermissions'), res => {
      permissions.value = res.data.meta_value
        .map(item => Object.assign(item, { is_allow: false }))
    })

    const fetchRole = id => http.handle(store.dispatch('role/fetchRole', { id }), res => {
      role.value = res.data
    })

    const createRole = data => http.handle(store.dispatch('role/createRole', data), res => {
      role.value = res.data
      return res
    })

    const updateRole = data => http.handle(store.dispatch('role/updateRole', data), res => {
      role.value = res.data
    })

    watch([role, permissions], () => {
      const list = role.value.permission || []
      list.forEach(item => {
        if (item.is_allow) {
          const found = permissions.value.find(p => p.code === item.code) || {}
          found.is_allow = true
        }
      })
    })

    return {
      role,
      pageMode,
      permissions,
      fetchRole,
      createRole,
      fetchPermission,
      avatarText,
      updateRole,
      tableColumns,
    }
  },
}
</script>

<style>

</style>
